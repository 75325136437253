package auth

import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto

internal object AuthScreenStyle : StyleSheet() {

    val contentClass by style {
        display(DisplayStyle.Block)
        alignContent(AlignContent.Center)
        minHeight(50.em)
    }

    val formsClass by style {
        marginTop(16.vh)
        maxWidth(250.px)
        marginLeft(auto.unsafeCast<CSSNumeric>())
        marginRight(auto.unsafeCast<CSSNumeric>())
    }
}