import org.jetbrains.compose.web.css.*


object Styles : StyleSheet(usePrefix = false) {

    val headerClass by style {
        backgroundColor(Color.black)
        position(Position.Fixed)
        top(0.px)
        justifyContent(JustifyContent.SpaceBetween)
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        width(100.percent)
        height(56.px)
    }

    val contentClass by style {
        backgroundColor(Color.black)
        paddingTop(56.px)
        width(100.percent)
        height(100.percent)
        minHeight(100.percent)
    }

    val footerClass by style {
        backgroundColor(Color.black)
        bottom(0.px)
        height(256.px)
    }

    val bodyClass by style {
        margin(0.px)
        height(100.percent)
        width(100.percent)
        backgroundColor(Color.black)
    }

    val columnClass by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
    }

    val rowClass by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
    }

    val headerTitleClass by style {
        hover {
            cursor("pointer")
            backgroundColor(Color.transparent)
        }
        active {
            cursor("grabbing")
            border { width(0.px) }
            backgroundColor(Color.transparent)
        }
        focus { backgroundColor(Color.transparent) }

        textDecoration("none")
        cursor("pointer")
        border { width(0.px) }
        backgroundColor(Color.transparent)
        color(Color.white)
        paddingLeft(24.px)
        paddingRight(24.px)
        fontSize(20.px)
    }

    val headerButtonClass by style {
        hover {
            cursor("pointer")
            backgroundColor(Color.transparent)
        }
        active {
            cursor("grabbing")
            border { width(0.px) }
            backgroundColor(Color.transparent)
        }
        focus { backgroundColor(Color.transparent) }

        textDecoration("none")
        cursor("pointer")
        border { width(0.px) }
        backgroundColor(Color.transparent)
        color(Color.white)
        paddingLeft(24.px)
        paddingRight(24.px)
        fontSize(16.px)
    }

    val footerCopyrightClass by style {
        color(Color.white)
        paddingRight(24.px)
        textAlign("end")
        fontSize(20.px)
    }
}