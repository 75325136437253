package auth

import androidx.compose.runtime.*
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun authScreen() {
    Style(AuthScreenStyle)

    var email by remember { mutableStateOf("") }
    var password by remember { mutableStateOf("") }

    Div(
        attrs = {
            classes(
                AuthScreenStyle.contentClass,
            )
        },
    ) {
        Div(
            attrs = {
                classes(
                    Styles.columnClass,
                    AuthScreenStyle.formsClass,
                )
            },
        ) {
            TextInput(

                value = email,
                attrs = {
                    placeholder("E-mail")
                    style {
                        height(40.px)
                        paddingLeft(8.px)
                        borderRadius(16.px)
                    }
                    onInput { email = it.value }
                },
            )
            PasswordInput(
                value = password,
                attrs = {
                    placeholder("Password")
                    style {
                        height(40.px)
                        marginTop(10.px)
                        paddingLeft(8.px)
                        borderRadius(16.px)
                    }
                    onInput { password = it.value }
                },
            )
            Button(
                attrs = {
                    style {
                        marginTop(24.px)
                        height(48.px)
                        fontSize(16.px)
                        backgroundColor(Color.azure)
                        borderRadius(30.px)
                    }
                },
            ) {
                Text("Login")
            }
        }
    }
}
