import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.Div


@Composable
fun homeScreen() {
    Div(
        attrs = {
            classes(
                Styles.columnClass,
            )
        }
    ) {
        Div(
            {style { height(70.em) }}
        ) {

        }
        Div(
            {style { height(70.em) }}
        ) {

        }
        Div(
            {style { height(70.em) }}
        ) {

        }
        Div(
            {style { height(70.em) }}
        ) {

        }
    }
}