sealed class Route(val path: String) {
    object Home : Route("/")
    object Auth : Route("#/auth")

    companion object {
        fun getRoute(byName: String): Route {
            return when (byName) {
                "/" -> Home
                "/auth" -> Auth
                else -> throw IllegalArgumentException("Route $byName not found")
            }
        }
    }
}