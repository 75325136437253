import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import auth.authScreen
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Footer
import org.jetbrains.compose.web.dom.Header
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.renderComposable

fun main() {
    renderComposable("root") {
        Style(Styles)
        document.body?.addClass(Styles.bodyClass)
        Body()
    }
}

val routes: MutableMap<Route, @Composable () -> Unit> = mutableMapOf()

@Composable
fun Body() {
    var screenS by remember { mutableStateOf<Route>(Route.Home) }

    Header(
        attrs = {
            classes(
                Styles.headerClass,
                Styles.rowClass
            )
        }
    ) {
        A(
            href = Route.Home.path,
            {
                classes(Styles.headerTitleClass)
            }
        ) {
            Text("OneXeor.dev")
        }
        Div(
            {
                style {
                    display(DisplayStyle.Flex)
                    flexDirection(FlexDirection.Row)
                }
            }
        ) {
            A(
                href = Route.Auth.path,
                {
                    classes(Styles.headerButtonClass)
                }
            ) {
                Text("Login")
            }
            A(
                href = Route.Auth.path,
                {
                    classes(Styles.headerButtonClass)
                }
            ) {
                Text("Registration")
            }
        }
    }
    Div(
        attrs = {
            classes(
                Styles.contentClass
            )
        }
    ) {
        when (screenS) {
            is Route.Home -> homeScreen()
            is Route.Auth -> authScreen()
        }
    }
    Footer(
        attrs = {
            style { justifyContent(JustifyContent.SpaceBetween) }
            classes(
                Styles.rowClass,
                Styles.footerClass,
                Styles.footerCopyrightClass
            )
        }
    ) {
        Div { }
        Text("August 2022")
    }
    route(Route.Home) { homeScreen() }
    route(Route.Home) { authScreen() }

    window.addEventListener("load", {
        println("*load ${window.location.hash}")
        val hashLength = window.location.hash.length
        val url = window.location.hash.slice(1..hashLength).ifEmpty { "/" }
        val route = Route.getRoute(url)
        screenS = route
    })
    window.addEventListener("hashchange", {
        println("*hashchange ${window.location.hash}")
        val hashLength = window.location.hash.length
        val url = window.location.hash.slice(1..hashLength).ifEmpty { "/" }
        val route = Route.getRoute(url)
        screenS = route
    });
}

fun route(path: Route, template: @Composable () -> Unit) {
    routes[path] = template
}



